import React, {useState, useEffect} from 'react'
import '../Timeline/TimelineCSS.css'
import axios, {CancelTokenSource} from 'axios'

import '../../../css/timeline.css'
import '../../../css/WebAppCatalogue.css'
import ToggleButton from '@mui/material/ToggleButton'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import {Loader, AlertModal} from '../../CommonFunctions/CommonFunction'
import {memberInfoByOrg, departmentInfoByOrg} from '../../modules/auth/redux/AuthCRUD'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import {List as VirtualizedList} from 'react-virtualized'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {
  GetAllCatalouge,
  GetWebAppByOrgAndCatalogueId,
  AddCategoryData,
  RemoveCategoryData,
  GetWebByOrgDeptAndCatalogueId,
  GetWebAppByOrgUserAndCatalogueId,
} from '../../services/Category.services'

import dayjs from 'dayjs'

let cancelTokenSource: CancelTokenSource | null = null

const Category = () => {
  const [loading, setLoading] = useState(false)
  const [filterdata, setFilterData] = useState<any[]>([])
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isSelectedCheckAll, setIsSelectedCheckAll] = useState(false)
  const [isCheck, setIsCheck] = useState<any>([])
  const [isSelectedCheck, setIsSelectedCheck] = useState<any>([])
  const [toggle, setToggle] = useState('website')
  const [category, setCategory] = useState<any>([])
  const [categoryId, setCategoryId] = useState('')
  const [categoryTitle, setCategoryTitle] = useState('')
  const [_, setTempCategoryTitle] = useState('')
  const [categoryData, setCategoryData] = useState<any>([])
  const [selectedCategoryData, setSelectedCategoryData] = useState<any>([])
  const [selecetedFilterData, setSelecetedFilterData] = useState<any[]>([])
  const [department, setDepartment] = useState<any[]>([])
  const [departmentId, setDepartmentId] = useState('')
  const [spinner, setSpinner] = useState(false)
  const [currentView, setCurrentView] = useState('Organization')
  const [selectedUser, setSelectedUser] = useState('')
  const [members, setMembers] = useState<any[]>([])
  const [copySelectedField, setCopySelectedField] = useState<any>()
  const [error, __] = useState(false)
  const [selectedDepartementForCopy, setSelectedDepartementForCopy] = useState<any>()
  const API_URL = process.env.REACT_APP_API_URL

  const searchInput = document.getElementById('searchInput')
  const selectedSearchInput = document.getElementById('selectedSearchInput')

  //to get all the departments
  const GetDepartment = async () => {
    try {
      const departments = await departmentInfoByOrg()

      if (departments.data.length === 0) setSpinner(false)
      const newList = departments.data.map((item: any) => {
        return {
          label: item.department,
          value: item.departmentId,
        }
      })

      setDepartmentId(newList[0].value)

      setDepartment(newList)
    } catch (err) {
      console.log(err)
    }
  }

  const emptifyValues = () => {
    setIsCheck([])
    setIsCheckAll(false)
    setIsSelectedCheck([])
    setIsSelectedCheckAll(false)
  }

  useEffect(() => {
    if (categoryId) {
      handleToggle(undefined, toggle, currentView)
    }
  }, [departmentId, selectedUser, toggle, categoryId])

  useEffect(() => {
    try {
      setSpinner(true)

      const fetchData = async () => {
        try {
          const allCatalogue = await GetAllCatalouge()

          const catalogueId = allCatalogue.data[0].catalougeId
          setCategoryId(catalogueId)
          setCategory(allCatalogue.data)
          setCategoryTitle(allCatalogue.data[0].catalogueName)
          setTempCategoryTitle(allCatalogue.data[0].catalogueName)

          setSpinner(false)
          setLoading(false)
        } catch (err) {
          setSpinner(false)
          console.log(err)
        }
      }

      fetchData()
    } catch (err) {
      console.log(err)
    }
  }, [])

  //to search in the records
  const handleSearchChange = (event: any) => {
    try {
      const query = event.target.value.toLowerCase()
      const filterList = categoryData.filter((list: any) => list.toLowerCase().includes(query))
      setFilterData(filterList)
    } catch (err) {
      console.log(err)
    }
  }

  //to select all the entries in the left div
  const handleSelectAll = (e: any) => {
    const isChecked = e.target.checked
    setIsCheckAll(isChecked)
    if (isChecked) {
      setIsCheck(filterdata)
    } else {
      setIsCheck([])
    }
  }

  //to select all in the right div
  const handleSelectedAll = (e: any) => {
    setIsSelectedCheckAll(!isSelectedCheckAll)
    setIsSelectedCheck(selecetedFilterData.map((data: any) => data.id))
    if (isSelectedCheckAll) {
      setIsSelectedCheck([])
    }
  }

  //to select single records
  const handleClick = (e: any) => {
    const {id, checked} = e.target

    setIsCheck((prevIsCheck: any) => {
      if (checked) {
        return [...prevIsCheck, id]
      } else {
        return prevIsCheck.filter((item: any) => item !== id)
      }
    })
  }

  //to handle the toggle between website and application updated
  const handleToggle = async (
    event: React.MouseEvent<HTMLElement> | undefined,
    newAlignment: string,
    view: string
  ) => {
    try {
      const searchInputValue = document.getElementById('searchInput') as HTMLInputElement | null
      const selectedSearchInputValue = document.getElementById(
        'selectedSearchInput'
      ) as HTMLInputElement | null
      if (searchInputValue && selectedSearchInputValue) {
        searchInputValue.value = ''
        selectedSearchInputValue.value = ''
      }

      setSpinner(true)

      emptifyValues()

      switch (view) {
        case 'Organization':
          getOrganizationData(newAlignment)
          break
        case 'Department':
          getDepartmentData(newAlignment)
          break
        case 'User':
          getUserData(newAlignment)
          break
        default:
          break
      }

      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const handleWebAppChange = async (
    event: React.MouseEvent<HTMLElement> | undefined,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      setToggle(newAlignment)
    }
  }

  //to handle the change in the category updated
  const handleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const title = event.target.options[event.target.selectedIndex].text
    setCategoryId(event.target.value)
    setTempCategoryTitle(title !== 'Add Category' ? title : '')
  }

  //to add to the respective category
  const addCategoryData = async () => {
    const searchInputValue = document.getElementById('searchInput') as HTMLInputElement | null
    const selectedSearchInputValue = document.getElementById(
      'selectedSearchInput'
    ) as HTMLInputElement | null
    if (searchInputValue && selectedSearchInputValue) {
      searchInputValue.value = ''
      selectedSearchInputValue.value = ''
    }
    const body = {
      catalogueMasterId: categoryId,
      organizationId: localStorage.getItem('org_Id'),
      productiveAppAndUrl: isCheck,
      departmentId: departmentId,
      userId: selectedUser,
    }

    const addUrl = toggle === 'website' ? 'AddProductiveUrl' : 'AddProductiveApp'
    try {
      emptifyValues()
      const addResponse = await AddCategoryData(body, addUrl)

      if (addResponse.data.success === true) {
        handleToggle(undefined, toggle, currentView).then(() => {
          AlertModal(addResponse.data.message, '', 'success', false, '#7066E0', 'Ok')
        })
      } else {
        AlertModal(addResponse.data.message, '', 'warning', false, '#7066E0', 'Ok')
      }
    } catch (err) {
      console.log(err)
      AlertModal(
        `Something went wrong!`,
        'Please try again later',
        'warning',
        false,
        '#7066E0',
        'Ok'
      )
    }
  }

  const handleInputSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase()
    const filterdataList = selectedCategoryData.filter((list: {appName: string; url: string}) =>
      toggle === 'application'
        ? list.appName.toLowerCase().includes(query)
        : list.url.toLowerCase().includes(query)
    )
    setSelecetedFilterData(filterdataList)
  }

  //handle a particular select click
  const handleSelectedClick = (e: any) => {
    const {id, checked} = e.target
    const arr: any = []
    setIsSelectedCheck([...isSelectedCheck, id])
    if (!checked) {
      setIsSelectedCheck(isSelectedCheck.filter((item: any) => item !== id))
    }
    if (isCheck) {
      setIsSelectedCheckAll(false)
    }
  }

  //to remove from the category
  const handleRemove = async () => {
    emptifyValues()
    ;(searchInput as HTMLInputElement).value = ''
    ;(selectedSearchInput as HTMLInputElement).value = ''

    const body = {
      organizationId: localStorage.getItem('org_Id'),
      ids: isSelectedCheck,
      departmentId: currentView === 'Department' ? departmentId : '',
      userId: currentView === 'User' ? selectedUser : '',
    }

    try {
      const deleteResponse = await RemoveCategoryData(body, 'DeleteCatalogueAppAndWeb')
      if (deleteResponse.data.success === true) {
        handleToggle(undefined, toggle, currentView).then(() => {
          AlertModal(deleteResponse.data.message, '', 'success', false, '#7066E0', 'Ok')
        })
      } else {
        AlertModal(deleteResponse.data.message, '', 'warning', false, '#7066E0', 'Ok')
      }
    } catch (err) {
      console.log(err)
      AlertModal(
        `Something went wrong!`,
        'Please try again later',
        'warning',
        false,
        '#7066E0',
        'Ok'
      )
    }
  }

  //to handle the change in department
  const handleDepartmentChange = async (id: string) => {
    setDepartmentId(id)
  }

  //get all users
  const getmember = () => {
    setSpinner(true)
    try {
      memberInfoByOrg()
        .then((res) => {
          const newList = res.data.map((item: any) => {
            return {
              label: item.fullName,
              value: item.userId,
            }
          })

          setMembers(newList)

          setSelectedUser(newList[0].value)
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (err) {
      console.log(err)
    }
  }

  //handle the toggle between organization, department and user
  const handleCurrentView = async (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setSpinner(true)
    if (newAlignment === null) {
      setSpinner(false)
      return
    }

    try {
      const searchInputValue = document.getElementById('searchInput') as HTMLInputElement | null
      const selectedSearchInputValue = document.getElementById(
        'selectedSearchInput'
      ) as HTMLInputElement | null
      if (searchInputValue && selectedSearchInputValue) {
        searchInputValue.value = ''
        selectedSearchInputValue.value = ''
      }

      setCurrentView(newAlignment)
      setDepartmentId('')
      setSelectedUser('')
      setCategoryData([])
      setFilterData([])
      setSelectedCategoryData([])
      setSelecetedFilterData([])
      if (newAlignment === 'Organization') {
        handleToggle(undefined, toggle, newAlignment)
      } else if (newAlignment === 'Department') {
        GetDepartment()
      } else if (newAlignment === 'User') {
        getmember()
      }
    } catch (err) {
      console.log(err)
    }
  }

  //to handle the change in the user
  const handleUserChange = async (user: any) => {
    setSelectedUser(user)
  }

  //to hanlde copy
  const copyFromOrgOrDept = async (e: any) => {
    e.preventDefault()
    setSpinner(true)

    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }
    const searchInputValue = document.getElementById('searchInput') as HTMLInputElement | null
    const selectedSearchInputValue = document.getElementById(
      'selectedSearchInput'
    ) as HTMLInputElement | null
    if (searchInputValue && selectedSearchInputValue) {
      searchInputValue.value = ''
      selectedSearchInputValue.value = ''
    }

    cancelTokenSource = axios.CancelToken.source()

    if (copySelectedField === 'Department') {
      axios
        .get(`${API_URL}/AppUrlCatalogue/CopyAppWebFromDeptToDept`, {
          params: {
            OrganizationId: localStorage.getItem('org_Id'),
            DepartmentIdFrom: selectedDepartementForCopy,
            DepartmentIdTo: departmentId,
            UserId: null,
            WebsiteOrApplication: toggle,
            CatalogueMasterId: categoryId,
            CreatedDate: dayjs().format(),
            CreatedBy: localStorage.getItem('userId'),
          },
          cancelToken: cancelTokenSource?.token,
        })
        .then((res) => {
          handleToggle(undefined, toggle, currentView).then(() => {
            AlertModal(res.data.message, '', 'success', false, '#7066E0', 'Ok')
          })

          setSpinner(false)
        })
        .catch((err) => {
          console.log(err)
          setSpinner(false)
        })
    } else {
      try {
        axios
          .get(`${API_URL}/AppUrlCatalogue/CopyAppWebFromOrgToDeptOrUser`, {
            params: {
              OrganizationId: localStorage.getItem('org_Id'),
              DepartmentId: currentView === 'Department' ? departmentId : '',
              UserId: currentView === 'User' ? selectedUser : '',
              WebsiteOrApplication: toggle,
              CatalogueMasterId: categoryId,
              CreatedDate: dayjs().format(),
              CreatedBy: localStorage.getItem('userId'),
            },
            cancelToken: cancelTokenSource?.token,
          })
          .then((res) => {
            if (res.data.success === true) {
              handleToggle(undefined, toggle, currentView).then(() => {
                AlertModal(res.data.message, '', 'success', false, '#7066E0', 'Ok')
              })
            } else {
              AlertModal(res.data.message, '', 'error', false, '#7066E0', 'Ok')
            }
            setSpinner(false)
          })
          .catch((err) => {
            console.log(err)
            setSpinner(false)
          })
      } catch (err) {
        console.log(err)
      }
    }
  }

  const getOrganizationData = async (newAlignment: string) => {
    setSpinner(true)

    try {
      if (newAlignment === 'website') {
        const UnSelectedWebByOrgAndCatalogueId = await GetWebAppByOrgAndCatalogueId(
          categoryId,
          'GetUnSelectedWebByOrgAndCatalogueId'
        )
        setCategoryData(UnSelectedWebByOrgAndCatalogueId.data.url)
        setFilterData(UnSelectedWebByOrgAndCatalogueId.data.url)

        const SelectedWebByOrgAndCatalogueId = await GetWebAppByOrgAndCatalogueId(
          categoryId,
          'GetWebByOrgAndCatalogMasterId'
        )

        setSelectedCategoryData(SelectedWebByOrgAndCatalogueId.data)
        setSelecetedFilterData(SelectedWebByOrgAndCatalogueId.data)
      } else {
        const UnSelectedAppByOrgAndCatalogueId = await GetWebAppByOrgAndCatalogueId(
          categoryId,
          'GetUnSelectedAppByOrgAndCatalogueId'
        )

        setCategoryData(UnSelectedAppByOrgAndCatalogueId.data.appName)
        setFilterData(UnSelectedAppByOrgAndCatalogueId.data.appName)

        const SelectedAppByOrgAndCatalogueId = await GetWebAppByOrgAndCatalogueId(
          categoryId,
          'GetAppByOrgAndCatalogMasterId'
        )

        setSelectedCategoryData(SelectedAppByOrgAndCatalogueId.data)
        setSelecetedFilterData(SelectedAppByOrgAndCatalogueId.data)
      }
      setSpinner(false)
    } catch (err) {
      console.log(err)
    }
  }

  const getDepartmentData = async (newAlignment: string) => {
    if (departmentId) {
      setSpinner(true)
      try {
        setCopySelectedField('organization')
        if (newAlignment === 'website') {
          const getUnselectedWebsite = await GetWebByOrgDeptAndCatalogueId(
            categoryId,
            departmentId,
            'GetUnSelectedWebByOrgDeptAndCatalogueId'
          )

          setCategoryData(getUnselectedWebsite.data.url)
          setFilterData(getUnselectedWebsite.data.url)

          const getSelectedWebsite = await GetWebByOrgDeptAndCatalogueId(
            categoryId,
            departmentId,
            'GetWebByOrgDeptAndCatalogMasterId'
          )

          setSelectedCategoryData(getSelectedWebsite.data)
          setSelecetedFilterData(getSelectedWebsite.data)
        } else {
          const getUnselectedApp = await GetWebByOrgDeptAndCatalogueId(
            categoryId,
            departmentId,
            'GetUnSelectedAppByOrgDeptAndCatalogueId'
          )

          setCategoryData(getUnselectedApp.data.appName)
          setFilterData(getUnselectedApp.data.appName)

          const selectedApp = await GetWebByOrgDeptAndCatalogueId(
            categoryId,
            departmentId,
            'GetAppByOrgDeptAndCatalogMasterId'
          )

          setSelectedCategoryData(selectedApp.data)
          setSelecetedFilterData(selectedApp.data)
        }
      } catch (err) {
        console.log(err)
      }
      setSpinner(false)
    }
  }

  const getUserData = async (newAlignment: string) => {
    if (selectedUser) {
      setSpinner(true)
      try {
        if (newAlignment === 'website') {
          const getUnselectedWebsite = await GetWebAppByOrgUserAndCatalogueId(
            categoryId,
            selectedUser,
            'GetUnSelectedWebByOrgUserAndCatalogueId'
          )

          setCategoryData(getUnselectedWebsite.data.url)
          setFilterData(getUnselectedWebsite.data.url)

          const getSelectedWebsite = await GetWebAppByOrgUserAndCatalogueId(
            categoryId,
            selectedUser,
            'GetWebByOrgUserAndCatalogMasterId'
          )

          setSelectedCategoryData(getSelectedWebsite.data)
          setSelecetedFilterData(getSelectedWebsite.data)
        } else {
          const getUnselectedApp = await GetWebAppByOrgUserAndCatalogueId(
            categoryId,
            selectedUser,
            'GetUnSelectedAppByOrgUserAndCatalogueId'
          )

          setCategoryData(getUnselectedApp.data.appName)
          setFilterData(getUnselectedApp.data.appName)

          const selectedApp = await GetWebAppByOrgUserAndCatalogueId(
            categoryId,
            selectedUser,
            'GetAppByOrgUserAndCatalogMasterId'
          )

          setSelectedCategoryData(selectedApp.data)
          setSelecetedFilterData(selectedApp.data)
        }
      } catch (err) {
        console.log(err)
      }

      setSpinner(false)
    }
  }

  return (
    <>
      <div className='card mb-3 px-5 py-3'>
        <h3 className='align-items-start flex-column mb-0 '>
          <span className='card-label fw-bolder fs-3 '>Productive App/Url</span>
        </h3>
      </div>
      <div className='card mb-3'>
        {/* begin::Header */}
        <div className='card-header border-0  '>
          <table>
            <tbody>
              <tr>
                <td>
                  <select className='form-select' onChange={(e) => handleChange(e)}>
                    {category.map((data: any, index: any) => (
                      <option
                        key={index}
                        value={data.catalougeId}
                        selected={data.catalougeId === categoryId}
                      >
                        {data.catalogueName}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <div className='row mx-1'>
                    <div
                      className=' fv-row'
                      //style={{ width: 'min-content', display: 'flex', alignItems: 'center' }}
                    >
                      <ToggleButtonGroup
                        color='primary'
                        value={toggle}
                        exclusive
                        onChange={handleWebAppChange}
                        aria-label='Platform'
                      >
                        <ToggleButton value='website'>Website</ToggleButton>

                        <ToggleButton value='application'>Application</ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='row'>
                    <div
                      className=' fv-row'
                      //style={{ width: 'min-content', display: 'flex', alignItems: 'center' }}
                    >
                      <ToggleButtonGroup
                        color='primary'
                        value={currentView}
                        exclusive
                        onChange={handleCurrentView}
                        aria-label='Platform'
                      >
                        <ToggleButton value='Organization'>Organization</ToggleButton>
                        <ToggleButton value='Department'>Department</ToggleButton>
                        <ToggleButton value='User'>User</ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>
                </td>
                {currentView !== 'Organization' && (
                  <>
                    <td>
                      <div style={{minWidth: '10rem', marginLeft: '0.7rem'}}>
                        {currentView === 'Department' ? (
                          <>
                            <Select
                              className='selectDropdown'
                              components={makeAnimated()}
                              value={department.filter((option) => option.value === departmentId)}
                              options={department}
                              placeholder='select a department'
                              onChange={(e: any) => {
                                handleDepartmentChange(e.value)
                                setCopySelectedField('organization')
                              }}
                              isClearable={false}
                              isSearchable={true}
                              closeMenuOnScroll={true}
                              styles={{
                                option: (provided: any, state: any) => ({
                                  ...provided,
                                  fontSize: '1.1rem',
                                  fontWeight: '500',
                                }),
                                control: (provided: any) => ({
                                  ...provided,
                                  fontSize: '1.1rem',
                                  fontWeight: '500',
                                }),
                              }}
                            />

                            {error && (
                              <div className='fv-plugins-message-container'>
                                <div className='text-danger mt-2 mx-3'>Select a department</div>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <Select
                              className='selectDropdown'
                              components={makeAnimated()}
                              value={members.filter((option) => option.value === selectedUser)}
                              options={members}
                              placeholder='select a user'
                              onChange={(e: any) => handleUserChange(e.value)}
                              isClearable={false}
                              isSearchable={true}
                              closeMenuOnScroll={true}
                              styles={{
                                option: (provided: any, state: any) => ({
                                  ...provided,
                                  fontSize: '1.1rem',
                                  fontWeight: '500',
                                }),
                                control: (provided: any) => ({
                                  ...provided,
                                  fontSize: '1.1rem',
                                  fontWeight: '500',
                                }),
                              }}
                            />
                            {error && (
                              <div className='fv-plugins-message-container'>
                                <div className='text-danger mt-2 mx-3'>Select a user</div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </td>

                    {spinner === false && selecetedFilterData.length === 0 && (
                      <Tippy
                        placement='top'
                        content='Click to copy from organization or department'
                      >
                        <td>
                          {!spinner && currentView === 'Department' && department.length > 0 ? (
                            <td>
                              <div className='d-flex align-items-center position-realtive justify-content-center'>
                                <label className='mx-3 fw-bolder '>Copy</label>
                                <Select
                                  components={makeAnimated()}
                                  defaultValue={{label: 'organization', value: 'organization'}}
                                  options={[
                                    {label: 'organization', value: 'organization'},
                                    {label: 'Department', value: 'Department'},
                                  ]}
                                  onChange={(item: any) => {
                                    setCopySelectedField(item.value)
                                  }}
                                  isClearable={false}
                                  isSearchable={true}
                                  closeMenuOnScroll={true}
                                />
                                {copySelectedField === 'Department' && (
                                  <span className='d-flex'>
                                    <label className='d-flex mx-3 align-items-center fw-bolder'>
                                      from
                                    </label>
                                    <Select
                                      components={makeAnimated()}
                                      options={department.filter(
                                        (item) => item.value !== departmentId
                                      )}
                                      onChange={(item: any) => {
                                        setSelectedDepartementForCopy(item.value)
                                      }}
                                      isClearable={false}
                                      isSearchable={true}
                                      closeMenuOnScroll={true}
                                    />
                                  </span>
                                )}
                                <div className='d-flex justify-content-evenly mx-2 '>
                                  <button className='btn btn-primary' onClick={copyFromOrgOrDept}>
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </td>
                          ) : (
                            currentView === 'User' && (
                              <td>
                                <a onClick={(e) => copyFromOrgOrDept(e)} href='' className='mx-3'>
                                  Copy from Organization / Department
                                </a>
                              </td>
                            )
                          )}
                        </td>
                      </Tippy>
                    )}
                  </>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className='d-flex justify-content-between'>
        <div className='card mb-5 mb-xl-8' style={{padding: '10px 25px', width: '49%'}}>
          <div className='card-header border-0 pt-5' style={{flexDirection: 'column'}}>
            {categoryTitle != '' ? (
              <h3 className=' align-items-start flex-column'>
                <span className='card-label fw-bolder  mb-1' style={{fontSize: '1.25rem'}}>
                  Add to {!spinner && categoryTitle}
                </span>
              </h3>
            ) : (
              ''
            )}
            <div style={{marginTop: '10px', position: 'relative'}}>
              <input
                type='text'
                style={{marginTop: 'unset', paddingLeft: '3rem'}}
                id='searchInput'
                className='form-control hght form-control-solid '
                placeholder={toggle === 'website' ? 'Search by URL' : 'Search by APP'}
                onChange={(e) => handleSearchChange(e)}
              />
              <img
                className='custom-placeholder'
                src={toAbsoluteUrl('/media/icons/duotune/art/custom-search.png')}
              ></img>
            </div>
          </div>
          <div className='card-body py-3' style={{height: '30rem', overflowY: 'auto'}}>
            <div className='table-responsive'>
              <div className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <div style={{scrollbarGutter: 'stable', overflowY: 'scroll'}}>
                  {spinner ? (
                    ''
                  ) : (
                    <tr className=' fw-bolder fs-6'>
                      <th className='w-25px'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='selectAll'
                            id='selectAll'
                            onChange={handleSelectAll}
                            checked={isCheckAll}
                          />
                        </div>
                      </th>

                      <th className=' table-header' style={{width: '100%'}}>
                        {toggle === 'website' ? 'Web Url' : 'App Name'}
                      </th>
                    </tr>
                  )}
                </div>
                <div id='customized'>
                  {spinner ? (
                    Loader('100px')
                  ) : (
                    <VirtualizedList
                      height={310}
                      width={200}
                      rowCount={filterdata.length}
                      rowHeight={50}
                      rowRenderer={({index, key, style}) => {
                        const url = filterdata[index]
                        return (
                          <tr key={key} style={style}>
                            <td style={{paddingRight: '0.75rem'}}>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input widget-13-check'
                                  key={url}
                                  type='checkbox'
                                  id={url}
                                  onChange={handleClick}
                                  checked={isCheck.includes(url)}
                                />
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div
                                  className='d-flex justify-content-start flex-column text'
                                  style={{fontSize: '1.1rem'}}
                                >
                                  {url}
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-evenly '>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={isCheck.length == 0}
              onClick={addCategoryData}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>

        <div className='card mb-5 mb-xl-8' style={{padding: '10px 25px', width: '49%'}}>
          {/* begin::Header */}
          <div className='card-header border-0 pt-5' style={{flexDirection: 'column'}}>
            {categoryTitle != '' ? (
              <h3 className=' align-items-start flex-column'>
                <span className='card-label fw-bolder mb-1' style={{fontSize: '1.25rem'}}>
                  {!spinner && categoryTitle}
                </span>
              </h3>
            ) : (
              ''
            )}
            <div style={{marginTop: '10px', position: 'relative'}}>
              <input
                type='text'
                style={{marginTop: 'unset', paddingLeft: '3rem'}}
                id='selectedSearchInput'
                className='form-control hght form-control-solid '
                placeholder={toggle === 'website' ? 'Search by URL' : 'Search by APP'}
                onChange={(e) => handleInputSearch(e)}
              />
              <img
                className='custom-placeholder'
                src={toAbsoluteUrl('/media/icons/duotune/art/custom-search.png')}
              ></img>
            </div>
          </div>
          <div className='card-body py-3' style={{height: '30rem', overflowY: 'auto'}}>
            <div className='table-responsive'>
              <div className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <div style={{scrollbarGutter: 'stable', overflowY: 'scroll'}}>
                  {spinner ? (
                    ''
                  ) : (
                    <tr className='fw-bolder fs-6'>
                      <th className='w-25px'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='selectedAll'
                            id='selectedAll'
                            onChange={handleSelectedAll}
                            checked={isSelectedCheckAll}
                          />
                        </div>
                      </th>

                      <th className=' table-header' style={{width: '100%'}}>
                        {toggle === 'website' ? 'Web Url' : 'App Name'}
                      </th>
                    </tr>
                  )}
                </div>
                {toggle === 'website' ? (
                  <div id='selected-customized'>
                    {spinner ? (
                      Loader('114px')
                    ) : (
                      <VirtualizedList
                        height={310}
                        width={200}
                        rowCount={selecetedFilterData.length}
                        rowHeight={50}
                        rowRenderer={({index, key, style}) => {
                          const url = selecetedFilterData[index]
                          return (
                            <tr key={key} style={style}>
                              <td style={{paddingRight: '0.75rem'}}>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                  <input
                                    className='form-check-input widget-13-check'
                                    key={url.id}
                                    type='checkbox'
                                    id={url.id}
                                    onChange={handleSelectedClick}
                                    checked={isSelectedCheck.includes(url.id)}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div
                                    className='d-flex justify-content-start flex-column text'
                                    style={{fontSize: '1.1rem'}}
                                  >
                                    {url.url}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <div id='selected-customized'>
                    {spinner ? (
                      Loader('114px')
                    ) : (
                      <VirtualizedList
                        height={310}
                        width={200}
                        rowCount={selecetedFilterData.length}
                        rowHeight={50}
                        rowRenderer={({index, key, style}) => {
                          const app = selecetedFilterData[index]
                          return (
                            <tr key={key} style={style}>
                              <td style={{paddingRight: '0.75rem'}}>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                  <input
                                    className='form-check-input widget-13-check'
                                    key={app.id}
                                    type='checkbox'
                                    id={app.id}
                                    onChange={handleSelectedClick}
                                    checked={isSelectedCheck.includes(app.id)}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div
                                    className='d-flex justify-content-start flex-column text'
                                    style={{fontSize: '1.1rem'}}
                                  >
                                    {app.appName}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-evenly '>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={isSelectedCheck.length == 0}
              onClick={handleRemove}
            >
              {!loading && <span className='indicator-label'>Remove</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          <style>
            {`
                #customized div{
                  width:unset !important;
                  max-width:100% !important;
                }

                

                #selected-customized div{
                  width:unset !important;
                  max-width:100% !important;
                }

              `}
          </style>
        </div>
      </div>
    </>
  )
}

export {Category}
