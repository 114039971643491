import {FC, useEffect, useState} from 'react'

import '../../../css/checkoutPopUp.css'
import {
  GetCheckoutUrl,
  GetPaymentSummary,
  getActivePlanByOrg,
  upgradePlanRequest,
} from '../../services/Payment.services'
import 'tippy.js/dist/tippy.css'
import {AlertModal, Loader} from '../../CommonFunctions/CommonFunction'

interface PopupProps {
  onClose: (hasAnyChange: any) => void
  handleMainIconClick: any
  paymentType: string
  planId: string
}

const CheckOutPopUp: FC<PopupProps> = ({onClose, paymentType, planId, handleMainIconClick}) => {
  const [hasChange, setHasChange] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [summary, setSummary] = useState<any>({})
  const [checkDisable, setCheckDisable] = useState<boolean>(false)
  const [extraUser, setExtraUser] = useState<number>(0)
  const [isUpdated, setIsUpdated] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Summary()
      } catch (error) {
        AlertModal('Something went wrong!', '', 'error', false, '#7066E0', 'Ok').then(() => {
          onClose(isUpdated)
        })
      }
    }
    fetchData()
  }, [])

  const Summary = async (extraUser?: any) => {
    try {
      //setSpinner(true)
      const getSummary = await GetPaymentSummary(planId, localStorage.getItem('org_Id'), extraUser)
      setSummary(getSummary.data)
      setSpinner(false)
      setCheckDisable(false)
    } catch (error) {
      AlertModal('Something went wrong!', '', 'error', false, '#7066E0', 'Ok').then(() => {
        onClose(isUpdated)
      })
    }
  }

  const handleIconClick = (value?: any) => {
    onClose(value)
  }

  const handleCheckout = async () => {
    try {
      if (paymentType === 'Online') {
        let body = {
          organizationId: summary.organizationId,
          billingPlanId: planId,
          adminEmail: localStorage.getItem('Email'),
          adminName: localStorage.getItem('name'),
          netAmount: summary.amount,
          amount: summary.totalAmount,
          noOfUsers: summary.userCount,
          activeUserCount: summary.activeUserCount,
          extraUserCount: extraUser || 0,
          discount: summary.discountPercentage,
          tax: summary.taxPercentage,
          currencyCode: summary.currency,
          discountAmount: summary.discountAmount,
          taxAmount: summary.taxAmount,
        }

        const getRedirectUrl = await GetCheckoutUrl(body)

        localStorage.setItem('sessionId', getRedirectUrl.data.sessionId)
        window.location.href = getRedirectUrl.data.redirectionUrl
      } else {
        const getPlanDetails = await getActivePlanByOrg()
        console.log(getPlanDetails.data)

        let body = {
          orgId: summary.organizationId,
          activePlanId: getPlanDetails.data.billingPlanId || '',
          newPlanId: planId,
          userId: localStorage.getItem('userId'),
          activeUserCount: summary.activeUserCount,
          extraUserCount: extraUser || 0,
        }

        upgradePlanRequest(body).then((res) => {
          if (res.data.success === true) {
            setIsUpdated(true)
          }
          AlertModal(
            res.data.message,
            '',
            res.data.success === true ? 'success' : 'error',
            false,
            '#7066E0',
            'Ok'
          ).then(() => {
            handleIconClick(true)
            handleMainIconClick()
          })
        })
      }
    } catch (error) {
      AlertModal('Something went wrong!', '', 'error', false, '#7066E0', 'Ok').then(() => {
        onClose(hasChange)
      })
    }
  }

  return (
    <div className='modal-container d-flex' id='kt_modal_checkout_plan'>
      <div style={{margin: 'auto', paddingLeft: '200px'}}>
        <div className=' ' style={{padding: '0rem 2.5rem 2rem 2.5rem'}}>
          <div className='checkout-container'>
            <div className='checkout-card checkout-cart'>
              <div className='d-flex checkout-title'>
                <label className='checkout-heading'>PAYMENT DETAILS</label>
                <i
                  className='fa fa-times'
                  onClick={() => handleIconClick()}
                  aria-hidden='true'
                  style={{
                    fontSize: '1.5rem',

                    marginRight: '2rem',
                    cursor: 'pointer',
                  }}
                ></i>
              </div>
              {spinner ? (
                Loader('50px')
              ) : (
                <div className='checkout-steps'>
                  <div className='checkout-step'>
                    <div className='checkout-payments'>
                      <div className='checkout-details'>
                        <span className='row'>User Count:</span>
                        <span className='col-lg-8 fv-row ms-3'>{summary.activeUserCount}</span>

                        <span className='row'>Extra User Count:</span>
                        <span className='col-lg-8 fv-row w-75 ms-3'>
                          <input
                            type='number'
                            max='999'
                            className='form-control form-control-border fs-4 p-1 '
                            //defaultValue={summary.extraUserCount}
                            value={extraUser}
                            onChange={(e) => {
                              setCheckDisable(true)
                              if (Number(e.target.value) <= 999) {
                                setExtraUser(Number(e.target.value))
                                Summary(e.target.value)
                              }
                            }}
                          ></input>
                          {/* <span
                            className='position-relative'
                            style={{top: '14px'}}
                            onClick={Summary}
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/refresh.svg'
                              className='svg-icon-2'
                            />
                          </span> */}
                        </span>

                        <span className='row'>Subtotal:</span>
                        <span className='col-lg-8 fv-row'>
                          {summary.currency === 'USD' ? '$' : '₹'}
                          {summary.amount}
                        </span>

                        {summary.discountAmount !== 0 && (
                          <>
                            <span className='row'>Discount Percentage:</span>
                            <span className='col-lg-8 fv-row ms-3'>
                              {summary.discountPercentage}%
                            </span>
                            <span className='row'>Discount:</span>
                            <span className='col-lg-8 fv-row'>
                              {summary.currency === 'USD' ? '$' : '₹'}
                              {summary.discountAmount}
                            </span>
                          </>
                        )}

                        {summary.taxPercentage !== 0 && summary.taxAmount !== 0 && (
                          <>
                            <span className='row'>Tax Rate:</span>
                            <span className='col-lg-8 fv-row ms-3'>{summary.taxPercentage}%</span>
                            <span className='row'>Tax Amount:</span>
                            <span className='col-lg-8 fv-row'>
                              {summary.currency === 'USD' ? '$' : '₹'}
                              {summary.taxAmount}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className='card checkout'>
              <div className='footer'>
                <label className='price'>
                  {summary.currency === 'USD' ? '$' : '₹'}
                  {summary.totalAmount}
                </label>
                <button className='checkout-btn' disabled={checkDisable} onClick={handleCheckout}>
                  Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style>{`
          .modal-container {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1000;
            animation: fadeIn 0.5s ease;
            
          }

          #div-scroller::-webkit-scrollbar-thumb {
            background-color: #C1C1C1 !important;
          }
          #div-scroller::-webkit-scrollbar {
            width:0.5rem
          }



          .modal-innerContainer {
            
            margin: 0 auto;
            background-color: #fff;
            border-radius: 6px;
            padding: 20px;
            position: relative;
            width:100%;
          }

        .custom-modal-header{
            padding-right: 29rem;
            font-size: 1.8rem;
            font-weight: 700;
        }

        .paginationBttns{
            width:23rem;
            margin: 2rem auto 0 auto;
        }

        .temp-class{
            padding: 0.5rem;
            width: 8rem;
            margin: 0;
            justify-content:center;
            margin:unset
        }


        input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


        `}</style>
    </div>
  )
}

export {CheckOutPopUp}
