import React, {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {
  GetCloudConfigurationOfOrg,
  UpdateCloudConfiguration,
  CheckConnection,
} from '../../../services/Configuration.services'
import {Loader, AlertModal} from '../../../CommonFunctions/CommonFunction'

const CloudConfiguration: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [selectedCloudType, setSelectedCloudType] = useState<any>('')
  const [storageOptionSelected, setStorageOptionSelected] = useState<any>([])
  const [displayStorageType, setDisplayStorageType] = useState<boolean>(false)
  const [apiCloudType, setApiCloudType] = useState<string>('')
  const [storageId, setStorageId] = useState<string>('')
  const [buttonName, setButtonName] = useState<string>('Update')

  const cloudType = [
    {
      label: 'Default',
      value: 'Default',
    },
    {
      label: 'AWS',
      value: 'AWS',
    },
    {
      label: 'Azure',
      value: 'Azure',
    },
    {
      label: 'Google',
      value: 'Google',
    },
  ]

  const liveRecordOptions = [
    {
      label: 'FTPS/SFTP',
      value: 'SFTP',
    },
    {
      label: 'AWS',
      value: 'AWS',
    },
    {
      label: 'Azure',
      value: 'Azure',
    },
    {
      label: 'Google',
      value: 'Google',
    },
  ]

  const storageOptions = [
    {
      label: 'Live Recording',
      value: 'LiveRecording',
    },
    {
      label: 'ScreenShots',
      value: 'ScreenShots',
    },
  ]

  const getValidationSchema = () => {
    if (selectedCloudType.value === 'AWS') {
      return Yup.object().shape({
        awsAccessKey: Yup.string().required('Access Key is required'),
        awsSecretKey: Yup.string().required('Secret Key is required'),
        awsRegion: Yup.string().required('Region is required'),
        storageName: Yup.string().required('Bucket Name is required'),
        storagePath: Yup.string(),
      })
    } else if (selectedCloudType.value === 'Azure') {
      return Yup.object().shape({
        azureConnectionString: Yup.string().required('Connection String is required'),
        storageName: Yup.string().required('Container Name is required'),
        storagePath: Yup.string(),
      })
    } else if (selectedCloudType.value === 'SFTP') {
      return Yup.object().shape({
        host: Yup.string().required('Host is required'),
        userName: Yup.string().required('User Name is required'),
        password: Yup.string().required('Password is required'),
        port: Yup.string().required('Port is required'),
      })
    } else if (selectedCloudType.value === 'Google') {
      return Yup.object().shape({
        googleCloudBucketName: Yup.string().required('Bucket Name is required'),
        googleCloudServiceAccountJson: Yup.mixed(),
      })
    }

    return Yup.object()
  }

  const initialValues = {
    storageId: '',
    userId: localStorage.getItem('userId'),
    storageKind: '',
    storageType: '',
    awsAccessKey: '',
    awsSecretKey: '',
    awsRegion: '',
    azureConnectionString: '',
    storageName: '',
    storagePath: '',
    host: '',
    userName: '',
    password: '',
    port: '',
    googleCloudBucketName: '',
    googleCloudServiceAccountJson: '',
    orgId: localStorage.getItem('org_Id'),
    //createdDate: dayjs().format(),
    //modifiedBy: localStorage.getItem('name'),
    //modifiedDate: dayjs().format()
  }

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setLoading(true)

      let updateDetails: any
      if (selectedCloudType.value === 'AWS') {
        const AWSBody = {
          storageId: storageId ? storageId : '',
          userId: values.userId,
          orgId: values.orgId,
          storageType: selectedCloudType.value,
          storageKind: storageOptionSelected.value,
          awsAccessKey: values.awsAccessKey,
          awsSecretKey: values.awsSecretKey,
          awsRegion: values.awsRegion,
          azureConnectionString: '',
          storageName: values.storageName,
          storagePath: values.storagePath,
          host: '',
          userName: '',
          password: '',
          port: 0,
        }
        let checkConnection = await CheckConnection(AWSBody)
        if (checkConnection.data.success === false) {
          AlertModal(
            checkConnection.data.message,
            '',
            checkConnection.data.success ? 'success' : 'error',
            false,
            '#7066E0',
            'Ok'
          ).then(() => {
            setLoading(false)
          })
        } else {
          updateDetails = await UpdateCloudConfiguration(AWSBody)
        }
      } else if (selectedCloudType.value === 'Azure') {
        const AzureBody = {
          orgId: values.orgId,
          storageId: storageId ? storageId : '',
          userId: values.userId,
          storageType: selectedCloudType.value,
          storageKind: storageOptionSelected.value,
          awsAccessKey: '',
          awsSecretKey: '',
          awsRegion: '',
          azureConnectionString: values.azureConnectionString,
          storageName: values.storageName,
          storagePath: values.storagePath,
          host: '',
          userName: '',
          password: '',
          port: 0,
        }
        let checkConnection = await CheckConnection(AzureBody)
        if (checkConnection.data.success === false) {
          AlertModal(
            checkConnection.data.message,
            '',
            checkConnection.data.success ? 'success' : 'error',
            false,
            '#7066E0',
            'Ok'
          ).then(() => {
            setLoading(false)
          })
        } else {
          updateDetails = await UpdateCloudConfiguration(AzureBody)
        }
      } else if (selectedCloudType.value === 'SFTP') {
        const FTPSBody = {
          orgId: values.orgId,
          storageId: storageId ? storageId : '',
          userId: values.userId,
          storageType: selectedCloudType.value,
          storageKind: storageOptionSelected.value,
          awsAccessKey: '',
          awsSecretKey: '',
          awsRegion: '',
          azureConnectionString: '',
          storageName: '',
          storagePath: values.storagePath,
          host: values.host,
          userName: values.userName,
          password: values.password,
          port: values.port,
        }
        let checkConnection = await CheckConnection(FTPSBody)
        if (checkConnection.data.success === false) {
          AlertModal(
            checkConnection.data.message,
            '',
            checkConnection.data.success ? 'success' : 'error',
            false,
            '#7066E0',
            'Ok'
          ).then(() => {
            setLoading(false)
          })
        } else {
          updateDetails = await UpdateCloudConfiguration(FTPSBody)
        }
      } else if (selectedCloudType.value === 'Google') {
        const GoogleBody = {
          orgId: values.orgId,
          storageId: storageId ? storageId : '',
          userId: values.userId,
          storageType: selectedCloudType.value,
          storageKind: storageOptionSelected.value,
          awsAccessKey: '',
          awsSecretKey: '',
          awsRegion: '',
          azureConnectionString: '',
          googleCloudBucketName: values.googleCloudBucketName,
          googleCloudServiceAccountJson: values.googleCloudServiceAccountJson,
          host: '',
          userName: '',
          password: '',
          port: 0,
        }
        let checkConnection = await CheckConnection(GoogleBody)
        if (checkConnection.data.success === false) {
          AlertModal(
            checkConnection.data.message,
            '',
            checkConnection.data.success ? 'success' : 'error',
            false,
            '#7066E0',
            'Ok'
          ).then(() => {
            setLoading(false)
          })
        } else {
          updateDetails = await UpdateCloudConfiguration(GoogleBody)
        }
      } else {
        const DefaultBody = {
          orgId: values.orgId,
          storageId: storageId ? storageId : '',
          userId: values.userId,
          storageType: selectedCloudType.value,
          storageKind: storageOptionSelected.value,
          awsAccessKey: '',
          awsSecretKey: '',
          awsRegion: '',
          azureConnectionString: '',
          storageName: '',
          storagePath: '',
          host: '',
          userName: '',
          password: '',
          port: 0,
        }
        let checkConnection = await CheckConnection(DefaultBody)
        if (checkConnection.data.success === false) {
          console.log('it came in the if block')
          AlertModal(
            checkConnection.data.message,
            '',
            checkConnection.data.success ? 'success' : 'error',
            false,
            '#7066E0',
            'Ok'
          ).then(() => {
            setLoading(false)
          })
        } else {
          updateDetails = await UpdateCloudConfiguration(DefaultBody)
        }
      }

      AlertModal(
        updateDetails.data.message,
        '',
        updateDetails.data.success ? 'success' : 'error',
        false,
        '#7066E0',
        'Ok'
      ).then(() => {
        setLoading(false)
      })
      //setLoading(false)
    },
    // validateOnChange: true,
    // validateOnBlur: true,
  })

  const GetOrgCloudConfiguration = async (value: any) => {
    try {
      setSpinner(true)
      const cloudDetails = await GetCloudConfigurationOfOrg(value.value)
      cloudDetails?.data.storageId === null ? setButtonName('Create') : setButtonName('Update')

      formik.setFieldValue('storageId', cloudDetails.data.storageId)
      formik.setFieldValue('storageType', cloudDetails.data.storageType)
      formik.setFieldValue('awsAccessKey', cloudDetails.data.awsAccessKey)
      formik.setFieldValue('awsSecretKey', cloudDetails.data.awsSecretKey)
      formik.setFieldValue('awsRegion', cloudDetails.data.awsRegion)
      formik.setFieldValue('azureConnectionString', cloudDetails.data.azureConnectionString)
      formik.setFieldValue('storageName', cloudDetails.data.storageName)
      formik.setFieldValue('storagePath', cloudDetails.data.storagePath)
      formik.setFieldValue('port', cloudDetails.data.port)
      formik.setFieldValue('userName', cloudDetails.data.userName)
      formik.setFieldValue('host', cloudDetails.data.host)
      formik.setFieldValue('password', cloudDetails.data.password)
      formik.setFieldValue('googleCloudBucketName', cloudDetails.data.googleCloudBucketName)
      formik.setFieldValue(
        'googleCloudServiceAccountJson',
        cloudDetails.data.googleCloudServiceAccountJson
      )

      setSelectedCloudType({
        value: cloudDetails.data.storageType,
        label: cloudDetails.data.storageType,
      })

      setApiCloudType(cloudDetails.data.storageType)
      setStorageId(cloudDetails.data.storageId)

      setSpinner(false)
    } catch (error) {
      setSpinner(false)
      console.log(error)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0' role='banner'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Cloud Details</h3>
        </div>
      </div>

      {spinner && Loader('50px')}

      {!spinner && (
        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                  Cloud Storage For{' '}
                </label>
                <div className='col-lg-8 fv-row'>
                  <div
                    className='min-w-200px'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Search Storage type'
                  >
                    <Select
                      className='selectDropdown'
                      components={makeAnimated()}
                      options={storageOptions}
                      value={storageOptionSelected}
                      placeholder='Select Storage For'
                      onChange={(item) => {
                        setStorageOptionSelected(item)
                        GetOrgCloudConfiguration(item)
                        setDisplayStorageType(true)
                      }}
                      isClearable={false}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                  </div>
                </div>
              </div>

              {displayStorageType && (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                    Cloud Type
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <div
                      className='min-w-200px'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      data-bs-trigger='hover'
                      title='Search Storage type'
                    >
                      <Select
                        className='selectDropdown'
                        components={makeAnimated()}
                        options={
                          storageOptionSelected.value === 'ScreenShots'
                            ? cloudType
                            : liveRecordOptions
                        }
                        value={selectedCloudType}
                        placeholder='Select Cloud Type'
                        onChange={(item: any) => {
                          setSelectedCloudType(item)
                          formik.resetForm()
                          if (item.value == apiCloudType) {
                            GetOrgCloudConfiguration(storageOptionSelected)
                          }
                        }}
                        isClearable={false}
                        isSearchable={true}
                        closeMenuOnScroll={true}
                      />
                    </div>
                  </div>
                </div>
              )}

              {selectedCloudType.value === 'AWS' && (
                <>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      Access Key
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='tel'
                        className='form-control form-control-border '
                        placeholder='Access Key'
                        {...formik.getFieldProps('awsAccessKey')}
                      />
                      {formik.touched.awsAccessKey && formik.errors.awsAccessKey && (
                        <div className='fv-plugins-message-container'>
                          <div className='text-danger mt-2'>{formik.errors.awsAccessKey}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      Secret Key
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-border '
                        placeholder='Secret Key'
                        {...formik.getFieldProps('awsSecretKey')}
                      />
                      {formik.touched.awsSecretKey && formik.errors.awsSecretKey && (
                        <div className='fv-plugins-message-container'>
                          <div className='text-danger mt-2'>{formik.errors.awsSecretKey}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Region</label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-border '
                        placeholder='Region'
                        {...formik.getFieldProps('awsRegion')}
                      />
                      {formik.touched.awsRegion && formik.errors.awsRegion && (
                        <div className='fv-plugins-message-container'>
                          <div className='text-danger mt-2'>{formik.errors.awsRegion}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      Bucket Name
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-border '
                        placeholder='Bucket Name'
                        {...formik.getFieldProps('storageName')}
                      />
                      {formik.touched.storageName && formik.errors.storageName && (
                        <div className='fv-plugins-message-container'>
                          <div className='text-danger mt-2'>{formik.errors.storageName}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Folder Path</label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-border '
                        placeholder='Folder Path'
                        {...formik.getFieldProps('storagePath')}
                      />
                      {formik.touched.storagePath && formik.errors.storagePath && (
                        <div className='fv-plugins-message-container'>
                          <div className='text-danger mt-2'>{formik.errors.storagePath}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              {selectedCloudType.value === 'Azure' && (
                <>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      Connection String
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <textarea
                        rows={3}
                        className='form-control form-control-border '
                        placeholder='Connection String'
                        {...formik.getFieldProps('azureConnectionString')}
                      />
                      {formik.touched.azureConnectionString && formik.errors.azureConnectionString && (
                        <div className='fv-plugins-message-container'>
                          <div className='text-danger mt-2'>
                            {formik.errors.azureConnectionString}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      Container Name
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-border '
                        placeholder='Container Name'
                        {...formik.getFieldProps('storageName')}
                      />
                      {formik.touched.storageName && formik.errors.storageName && (
                        <div className='fv-plugins-message-container'>
                          <div className='text-danger mt-2'>{formik.errors.storageName}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Folder Path</label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-border '
                        placeholder='Folder Path'
                        {...formik.getFieldProps('storagePath')}
                      />
                      {formik.touched.storagePath && formik.errors.storagePath && (
                        <div className='fv-plugins-message-container'>
                          <div className='text-danger mt-2'>{formik.errors.storagePath}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              {selectedCloudType.value === 'SFTP' && (
                <>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Host</label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-border '
                        placeholder='Host'
                        {...formik.getFieldProps('host')}
                      />
                      {formik.touched.host && formik.errors.host && (
                        <div className='fv-plugins-message-container'>
                          <div className='text-danger mt-2'>{formik.errors.host}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      User Name
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-border '
                        placeholder='User Name'
                        {...formik.getFieldProps('userName')}
                      />
                      {formik.touched.userName && formik.errors.userName && (
                        <div className='fv-plugins-message-container'>
                          <div className='text-danger mt-2'>{formik.errors.userName}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      Password
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='password'
                        autoComplete='new-password'
                        className='form-control form-control-border '
                        placeholder='Password'
                        {...formik.getFieldProps('password')}
                      />
                      {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                          <div className='text-danger mt-2'>{formik.errors.password}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Port</label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-border '
                        placeholder='Port'
                        {...formik.getFieldProps('port')}
                      />
                      {formik.touched.port && formik.errors.port && (
                        <div className='fv-plugins-message-container'>
                          <div className='text-danger mt-2'>{formik.errors.port}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Folder</label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-border '
                        placeholder='Folder'
                        {...formik.getFieldProps('storagePath')}
                      />
                      {formik.touched.storagePath && formik.errors.storagePath && (
                        <div className='fv-plugins-message-container'>
                          <div className='text-danger mt-2'>{formik.errors.storagePath}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              {selectedCloudType.value === 'Google' && (
                <>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      Bucket Name
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-border '
                        placeholder='Bucket Name'
                        {...formik.getFieldProps('googleCloudBucketName')}
                      />
                      {formik.touched.googleCloudBucketName && formik.errors.googleCloudBucketName && (
                        <div className='fv-plugins-message-container'>
                          <div className='text-danger mt-2'>
                            {formik.errors.googleCloudBucketName}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      Service Account JSON File
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <textarea
                        rows={3}
                        className='form-control form-control-border '
                        placeholder='Service Account JSON File Key'
                        {...formik.getFieldProps('googleCloudServiceAccountJson')}
                      />
                      {formik.touched.googleCloudServiceAccountJson &&
                        formik.errors.googleCloudServiceAccountJson && (
                          <div className='fv-plugins-message-container'>
                            <div className='text-danger mt-2'>
                              {formik.errors.googleCloudServiceAccountJson}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              {/* <button className='btn btn-secondary' onClick={() => navigate(status())}>
              Cancel
            </button> */}

              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && <span className='indicator-label'>{buttonName}</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  )
}

export default CloudConfiguration
