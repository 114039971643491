import React, {useState, useEffect} from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import {ProductivityChart} from '../../Web&App/ProductivityChart'
import {Loader, MultiDateTimePicker} from '../../../CommonFunctions/CommonFunction'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {GetAssignee} from '../../../services/GetAllAssinee.services'
import {RootState} from '../../../../setup'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {userInfoActions} from '../../../modules/auth'
import {utils as XLSXUtils, writeFile} from 'xlsx'

const API_URL = process.env.REACT_APP_API_URL

const ProductivitysEmp: React.FC = () => {
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const dispatch = useDispatch()
  const [userRecords, setUserRecords] = useState<any[]>([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const [spinner, setSpinner] = useState(false)
  const [dateError, setDateError] = useState(false)
  const [optionSelectedMember, setOptionSelectedMember] = useState<any>('')
  const [memberOption, setMemberOption] = useState<any>([])

  useEffect(() => {
    if (ConfigDetails.selectedUserId) {
      setOptionSelectedMember(ConfigDetails.selectedUserId)
    } else {
      let userId = localStorage.getItem('userId')
      setOptionSelectedMember(userId)
    }
  }, [])

  useEffect(() => {
    dateRange()
  }, [startDate, endDate, optionSelectedMember])

  //to get the data of a particular range
  const dateRange = () => {
    setSpinner(true)
    if (startDate) {
      let newEndDate: any
      if (endDate == null) {
        newEndDate = startDate
      } else {
        newEndDate = endDate
      }

      const convertedEndDate = dayjs(newEndDate).format('YYYY-MM-DDT00:00:00[Z]')
      const convertedStartDate = dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]')

      const body = {
        organizationId: localStorage.getItem('org_Id'),
        userId: optionSelectedMember,
        fromDate: convertedStartDate,
        toDate: convertedEndDate,
      }
      if (optionSelectedMember) {
        axios
          .post(`${API_URL}/TotalProductiveHours/GetTotalProductiveHoursByUser`, body)
          .then((res) => {
            setUserRecords(res.data)
            setSpinner(false)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }

  //seconds to HH:mm:Ss
  const convertSecondsToFormatter = (time: number) => {
    return dayjs().startOf('day').second(time).format('HH:mm:ss')
  }

  useEffect(() => {
    startDate ? setDateError(false) : setDateError(true)
  }, [startDate])

  useEffect(() => {
    getAllAssinee()
  }, [])

  const getAllAssinee = async () => {
    try {
      const assignees = await GetAssignee()
      const newList = assignees?.data.map((item: any) => {
        return {
          label: item.assigneeName,
          value: item.assigneeId,
        }
      })

      setMemberOption(newList)
    } catch (error) {
      console.log(error)
    }
  }

  const handleDateChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const createExcel = (e: any) => {
    e.preventDefault()

    const worksheetData = userRecords.map((data) => {
      const fromDate = dayjs(data.fromDate).format('DD/MM/YYYY')

      const username = data.userName

      const productiveTime = dayjs()
        .startOf('day')

        .second(data.totalProductiveTime)

        .format('HH:mm:ss')

      const idleTime = dayjs().startOf('day').second(data.totalIdleTime).format('HH:mm:ss')

      const awayTime = dayjs().startOf('day').second(data.awayTime).format('HH:mm:ss')

      const unproductiveTime = dayjs()
        .startOf('day')

        .second(data.unproductiveTime)

        .format('HH:mm:ss')

      return [fromDate, username, productiveTime, idleTime, awayTime, unproductiveTime]
    })

    const name = `${dayjs(startDate).format('DD/MM/YYYY')}-${
      endDate ? dayjs(endDate).format('DD/MM/YYYY') : dayjs(startDate).format('DD/MM/YYYY')
    }`

    const worksheet = XLSXUtils.aoa_to_sheet([
      ['Date', 'Name', 'Productive Time', 'Idle Time', 'Away Time', 'Unproductive Time'],

      ...worksheetData,
    ])

    const workbook = XLSXUtils.book_new()

    XLSXUtils.book_append_sheet(workbook, worksheet, 'Sheet 1')

    writeFile(workbook, `Productive vs Idle-${name}.xlsx`)
  }

  return (
    <>
      <form className='form'>
        <div className='card-body'>
          <div className='tab-content pt-3'>
            <div>
              <div className='card-header border-0 pt-5'>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px 16px',
                    alignItems: 'center',
                  }}
                >
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>Productive vs Idle</span>
                  </h3>
                  <div
                    style={{
                      marginRight: '15px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {localStorage.getItem('role') !== process.env.REACT_APP_SYSTEM_FIRST_ROLE &&
                      memberOption.length > 1 && (
                        <div
                          style={{marginRight: '10px', width: '200px'}}
                          className='card-toolbar'
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                          data-bs-trigger='hover'
                        >
                          <Select
                            components={makeAnimated()}
                            value={memberOption.filter(
                              (option: any) => option.value === optionSelectedMember
                            )}
                            options={memberOption}
                            placeholder='Select Member'
                            onChange={(item: any) => {
                              dispatch(
                                userInfoActions.updateSelectedUser({
                                  selectedUserName: item.label,
                                  selectedUserId: item.value,
                                })
                              )
                              setOptionSelectedMember(item.value)
                              // SendDataOfUser(item.value)
                            }}
                            isClearable={false}
                            isSearchable={true}
                            closeMenuOnScroll={true}
                          />
                        </div>
                      )}

                    <div style={{display: 'contents'}}>
                      <div style={{marginRight: '15px'}} id='select-date'>
                        {MultiDateTimePicker(
                          startDate,
                          endDate,
                          handleDateChange,
                          'form-control w-17'
                        )}

                        {dateError && (
                          <div className='fv-plugins-message-container'>
                            <div className='text-danger mt-2 mx-3'>Select a date</div>
                          </div>
                        )}
                      </div>
                    </div>
                    <button
                      className={`btn btn-primary d-flex align-items-center ${
                        userRecords.length == 0 && 'disabled'
                      }`}
                      style={{height: '3rem'}}
                      onClick={(e) => createExcel(e)}
                    >
                      <i className='fa fa-download'></i>Download
                    </button>
                  </div>
                </div>
                <div className='card-body py-3 mt-4'>
                  <div className='table-responsive' id='custom-apexChart'>
                    {userRecords.length === 0 && (
                      <h2 className='noRecordFound'>No Records Found</h2>
                    )}
                    {userRecords.length > 0 && (
                      <table className='table align-middle gs-0 gy-4'>
                        <thead>
                          <tr className='fw-bolder  bg-light fs-5 bg-secondary'>
                            <th></th>
                            <th className=' min-w-120px fs-6 text-center'>Date</th>
                            <th className='min-w-100px fs-6 text-center'>Productive Time</th>
                            <th className='min-w-100px fs-6 text-center'>Unproductive Time</th>
                            <th className='min-w-100px fs-6 text-center'>Idle Time</th>
                            <th className='min-w-100px fs-6 text-center'>Away Time</th>
                            <th className='min-w-100px fs-6 text-center'>Total Time</th>

                            <th className='min-w-150px fs-6 text-center'>Productivity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {spinner
                            ? ''
                            : userRecords.map((record, index) => (
                                <tr key={index} style={{fontSize: '12px'}}>
                                  <td></td>

                                  <td>
                                    <span className='text-dark  d-flex mb-1 fs-6'>
                                      {new Date(record.fromDate).toLocaleDateString('en-GB')}
                                    </span>
                                  </td>
                                  <td className='text-center'>
                                    <span className='text-success  d-flex mb-1 mx-6 fs-6 '>
                                      {record.totalProductiveTime
                                        ? convertSecondsToFormatter(
                                            Number(record.totalProductiveTime)
                                          )
                                        : ''}
                                    </span>
                                  </td>

                                  <td>
                                    <span
                                      className=' d-flex mb-1 fs-6 mx-7'
                                      style={{color: '#FF6178'}}
                                    >
                                      {record.totalTime
                                        ? dayjs()
                                            .startOf('day')
                                            .second(record.unproductiveTime)
                                            .format('HH:mm:ss')
                                        : ''}
                                    </span>
                                  </td>

                                  <td>
                                    <span
                                      className='d-flex mb-1 fs-6 mx-6 justify-content-center'
                                      style={{color: '#FBBC04'}}
                                    >
                                      {record.totalIdleTime
                                        ? convertSecondsToFormatter(Number(record.totalIdleTime))
                                        : ''}
                                    </span>
                                  </td>

                                  <td>
                                    <span
                                      className=' d-flex mb-1 fs-6 mx-6 justify-content-center'
                                      style={{color: '#46B3A9'}}
                                    >
                                      {record.totalTime
                                        ? dayjs()
                                            .startOf('day')
                                            .second(record.awayTime)
                                            .format('HH:mm:ss')
                                        : ''}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className=' d-flex mb-1 fs-6 mx-6 justify-content-center'
                                      style={{color: 'rgb(98, 55, 221'}}
                                    >
                                      {record.totalTime
                                        ? dayjs()
                                            .startOf('day')
                                            .second(record.totalTime)
                                            .format('HH:mm:ss')
                                        : ''}
                                    </span>
                                  </td>

                                  <td>
                                    <span>
                                      <ProductivityChart
                                        className=''
                                        fromDate={record.fromDate}
                                        productiveTime={
                                          record.totalProductiveTime
                                            ? record.totalProductiveTime
                                            : ''
                                        }
                                        idleTime={record.totalIdleTime ? record.totalIdleTime : ''}
                                        unproductive={
                                          record.unproductiveTime ? record.unproductiveTime : ''
                                        }
                                        away={record.awayTime ? record.awayTime : ''}
                                        selectedUser={optionSelectedMember}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                        <style>{`
            

            #custom-apexChart #apexchartsmychart.apexcharts-canvas  {
              position:unset !important;
            }

            
          


          `}</style>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {spinner ? Loader('100px') : ''}
    </>
  )
}

export {ProductivitysEmp}
