import {AlertModal} from '../../app/CommonFunctions/CommonFunction'
import * as auth from '../../app/modules/auth/redux/AuthRedux'

export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = 'application/json'

  // Function to check if a field is allowed to be empty for a specific URL
  const isFieldAllowedEmpty = (url: string, field: string): boolean => {
    const allowedEmptyFieldsPerEndpoint: Record<string, string[]> = {
      '/OverviewReport/OverviewReportForUser': ['departmentId', 'DepartmentId'],
      '/OverviewReport/OverviewReportForManager': ['departmentId'],
      '/OverviewReport/OverviewReportForOrganization': ['departmentId'],
      '/Registration/Register': [
        'address',
        'baseLocation',
        'contactNo',
        'departmentId',
        'empId',
        'managerId',
      ],
      '/Registration/UpdateUsers': [
        'address',
        'baseLocation',
        'contactNo',
        'departmentId',
        'empId',
        'managerId',
      ],
      '/TimeClaim/AddUserTimeToClaimByAdminForStoppedorOffline': ['claimStatus'],
      '/AppUrlCatalogue/AddProductiveUrl': ['departmentId', 'userId'],
      '/AppUrlCatalogue/DeleteCatalogueAppAndWeb': ['departmentId', 'userId'],
      '/AppUrlCatalogue/AddProductiveApp': ['departmentId', 'userId'],
      '/Calculate/DownloadTimeTrackerReport': ['userId'],
      '/AttendanceReport/DownloadAttendanceReport': ['userId'],
      '/BillingPlan/AddChangePlanRequest': ['activePlanId'],
      '/TimeClaim/AddUserTimeToClaimForStoppedorOffline': ['claimStatus'],
      '/CloudStorageConfiguration/TestCloudConnection': [
        'awsAccessKey',
        'awsRegion',
        'awsSecretKey',
        'azureConnectionString',
        'host',
        'password',
        'storageId',
        'storageName',
        'storagePath',
        'userName',
      ],
      '/CloudStorageConfiguration/UpdateCloudStorage': [
        'awsAccessKey',
        'awsRegion',
        'awsSecretKey',
        'azureConnectionString',
        'host',
        'password',
        'storageId',
        'storageName',
        'storagePath',
        'userName',
      ],
      '/AppUrlCatalogue/CopyAppWebFromOrgToDeptOrUser': ['DepartmentId', 'UserId'],
      '/Organization/RegisterOrganization': [
        'ContractDocFile',
        'TaxNumber',
        'emp_Strength',
        'org_Address',
        'org_ContactNo',
      ],
      '/Organization/UpdateOrganization': [
        'ContractDocFile',
        'ContractDocFileName',
        'TaxNumber',
        'emp_Strength',
        'modifiedBy',
        'org_Address',
        'org_ContactNo',
      ],
      '/AppUrlCatalogue/CopyAppWebFromDeptToDept': ['UserId'],
    }

    // Check if the current endpoint allows this field to be empty
    const endpoint = Object.keys(allowedEmptyFieldsPerEndpoint).find((endpoint) =>
      url.includes(endpoint)
    )

    return endpoint ? allowedEmptyFieldsPerEndpoint[endpoint].includes(field) : false
  }

  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      if (config.data && typeof config.data === 'object') {
        for (const key in config.data) {
          if (config.data.hasOwnProperty(key)) {
            const value = config.data[key]
            // Skip validation for fields allowed to be empty for this endpoint
            if (isFieldAllowedEmpty(config.url, key)) {
              continue
            }

            if (value === undefined || value === null || value === '') {
              store.dispatch(auth.actions.logout())
              localStorage.clear()
              AlertModal(
                `Some required fields are missing in the request body`,
                '',
                'warning',
                false,
                '#7066E0',
                'Ok'
              )

              return Promise.reject(
                new Error(`Field '${key}' in request body is missing or invalid`)
              )
            }
          }
        }
      }

      if (config.params && typeof config.params === 'object') {
        for (const key in config.params) {
          if (config.params.hasOwnProperty(key)) {
            const value = config.params[key]

            // Skip validation for fields allowed to be empty for this endpoint
            if (isFieldAllowedEmpty(config.url, key)) {
              continue
            }

            if (value === undefined || value === null || value === '') {
              store.dispatch(auth.actions.logout())
              localStorage.clear()
              AlertModal(
                `Some required fields are missing in the query parameters`,
                '',
                'warning',
                false,
                '#7066E0',
                'Ok'
              )

              return Promise.reject(
                new Error(`Field '${key}' in query parameters is missing or invalid`)
              )
            }
          }
        }
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}
