import axios, {CancelTokenSource} from 'axios'
import {UserModel} from '../models/UserModel'
const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/token/verifytoken`
export const LOGIN_URL = `${API_URL}/Login/login`
export const REGISTER_URL = `${API_URL}/register`
export const MEMBER_URL = `${API_URL}/member`
export const COMPNAME_URL = `${API_URL}/Organization/GetOrganization`
export const INACTIVE_COMPNAME_URL = `${API_URL}/Organization/GetAllInactiveOrganizations`
export const COMPANY_URL = `${API_URL}/company`
export const MEMBERS_URL = `${API_URL}/Registration/GetAllUsers`
export const MEMBERS_ORG = `${API_URL}/Registration/GetAllUsersByOrganization`
export const PROJECTS_URL = `${API_URL}/Projects/GetAllProject`
export const PROJECT_URL = `${API_URL}/project`
export const REQUEST_PASSWORD_URL = `${API_URL}/resetpassword`
export const ROLES = `${API_URL}/Roles/GetRoles`
export const ALL_MANAGERS_URL = `${API_URL}/Registration/GetAllManagersByOrganization`
export const ALL_MEMBER_UNDER_MANAGER = `${API_URL}/Registration/GetAllUsersByManager`
export const DEPARTMENT_ORG = `${API_URL}/Department/GetDepartment`
export const GET_ALL_ORGANIZATIONS = `${API_URL}/MeraMonitor/Overview`
export const ALL_TIME_ZONE = `${API_URL}/TimeZone/GetTimeZone`
export const CONFIG_INFO = `${API_URL}/AppLabelInformation/GetAllAppInformation`

let cancelTokenSource: CancelTokenSource | null = null

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  })
}
export function register(
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  confirmpassword: string
) {
  return axios.post(REGISTER_URL, {
    firstName,
    lastName,
    email,
    password,
    confirmpassword,
  })
}

export function companyInfo() {
  return axios.get(COMPNAME_URL)
}
export function inactiveCompanyInfo() {
  return axios.get(INACTIVE_COMPNAME_URL)
}

export function projectInfo() {
  return axios.get(PROJECTS_URL, {
    params: {
      Org_Id: localStorage.getItem('org_Id'),
    },
  })
}

export function memberInfo(orgId?: any) {
  return axios.get(MEMBERS_URL, {
    params: {
      Org_Id: orgId || localStorage.getItem('org_Id') || null,
    },
  })
}

export function memberInfoByOrg(orgId?: any) {
  cancelTokenAxios()
  cancelTokenSource = axios.CancelToken.source()
  return axios.get(MEMBERS_ORG, {
    params: {
      Org_Id: orgId || localStorage.getItem('org_Id'),
    },
    cancelToken: cancelTokenSource.token,
  })
}

export function organizationInfo(orgId?: any) {
  return axios.get(GET_ALL_ORGANIZATIONS)
}

const cancelTokenAxios = () => {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('New request initiated')
  }
}

export function callAllManager(orgId?: any) {
  cancelTokenAxios()
  cancelTokenSource = axios.CancelToken.source()
  return axios.get(ALL_MANAGERS_URL, {
    params: {
      Org_Id: orgId || localStorage.getItem('org_Id'),
    },
    cancelToken: cancelTokenSource.token,
  })
}

export function getTimeZone() {
  cancelTokenAxios()
  cancelTokenSource = axios.CancelToken.source()
  return axios.get(ALL_TIME_ZONE)
}

// export function callMemberUnderManager(id?: any, orgId?: any) {
//   cancelTokenAxios()
//   cancelTokenSource = axios.CancelToken.source()
//   return axios.get(ALL_MEMBER_UNDER_MANAGER, {
//     params: {
//       Org_Id: orgId || localStorage.getItem('org_Id'),
//       managerId: id || localStorage.getItem('userId'),
//     },
//     cancelToken: cancelTokenSource.token,
//   })
// }

export function getRoles() {
  return axios.get(ROLES)
}

export function project(
  projectName: string,
  projectId: string,
  domain: string,
  company: string,
  projectLead: string
) {
  return axios.post(PROJECT_URL, {
    projectName,
    projectId,
    domain,
    company,
    projectLead,
  })
}
// Server should return AuthModel
export function member(
  images: object,
  firstName: string,
  lastName: string,
  id: string,
  email: string,
  contact: string,
  password: string,
  country: string,
  designation: string,
  company: string,
  domain: string,
  projectLead: string,
  timeZone: string
) {
  return axios.post(MEMBER_URL, {
    images,
    firstName,
    lastName,
    id,
    email,
    contact,
    password,
    country,
    company,
    domain,
    projectLead,
    designation,
    timeZone,
  })
}

export function company(companyName: string, companyId: string, Project: string) {
  return axios.post(COMPANY_URL, {
    companyName,
    companyId,
    Project,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(obj: Object, id: string) {
  return axios.post<{result: boolean}>(`${REQUEST_PASSWORD_URL}/${id}`, obj)
}

export function getUserByToken(token: string) {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: {api_token: token},
  })
}

export function departmentInfoByOrg() {
  return axios.get(DEPARTMENT_ORG, {
    params: {
      OrganizationId: localStorage.getItem('org_Id'),
    },
  })
}

export function callConfigInfo() {
  return axios.get(CONFIG_INFO)
}
