import axios, {CancelTokenSource} from 'axios'
import {useEffect, useRef, useState} from 'react'
import dayjs from 'dayjs'
import moment from 'moment'
import {KTSVG} from '../../../_metronic/helpers'
import Tippy from '@tippyjs/react'
import {
  Pagination,
  Loader,
  RowsPerPage,
  SingleDatePickerComponent,
  AlertModal,
} from '../../CommonFunctions/CommonFunction'
import {convertSecIntoAMPM, getColorStatus} from '../Common_Function/Function'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {memberInfoByOrg} from '../../modules/auth/redux/AuthCRUD'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../setup'
import {userInfoActions} from '../../modules/auth'

const API = process.env.REACT_APP_API_URL
let cancelTokenSource: CancelTokenSource | null = null

const TimeClaim: React.FC = () => {
  const role = localStorage.getItem('role')
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState(new Date())
  const [timeData, setTimeData] = useState<any[]>([])
  const [screenloader, setScreenLoader] = useState<boolean>(false)
  const [userDetail, setUserDetail] = useState<any>({})
  const [updateState, setUpdateState] = useState<any>('')
  const [claimStatus, setClaimStatus] = useState('')
  const [memberOption, setMemberOption] = useState<any[]>([])
  const [optionSelectedMember, setOptionSelectedMember] = useState<any>('')
  const [_, setForcePage] = useState<any>(null)
  const [timeError, setTimeError] = useState<boolean>(false)

  const convertSecIntoHoursMinSec = (totalSeconds: any) => {
    const duration = moment.duration(totalSeconds, 'seconds')
    const hours = Math.floor(duration.asHours()).toString().padStart(2, '0')
    const formattedTime = moment.utc(totalSeconds * 1000).format(hours + ':mm')

    return formattedTime === '00:00:00' ? '-' : formattedTime
  }

  const earlyStageCall = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }

    setTimeData([])
    setClaimStatus('')
    setScreenLoader(true)
    setItemOffset(0)
  }

  const handleDateChange = (dates: any) => {
    setStartDate(dates)
    setUpdateState(null)
    getTimeData(dayjs(dates).format('YYYY-MM-DDT00:00:00[Z]'), optionSelectedMember.value)
  }

  useEffect(() => {
    const isGroupAdmin = localStorage.getItem('isGroupAdmin')
    const userId = localStorage.getItem('userId')
    const userName = localStorage.getItem('name')
    const selectedUserId = ConfigDetails.selectedUserId
    const selectedUserName = ConfigDetails.selectedUserName
    const role = ConfigDetails.role
    const SYSTEM_FIRST_ROLE = process.env.REACT_APP_SYSTEM_FIRST_ROLE
    const SYSTEM_SECOND_ROLE = process.env.REACT_APP_SYSTEM_SECOND_ROLE
    const SYSTEM_FIFTH_ROLE = process.env.REACT_APP_SYSTEM_FIFTH_ROLE

    const updateMemberSelection = (label: any, value: any) => {
      setOptionSelectedMember({label, value})
      getTimeData(dayjs().format('YYYY-MM-DDT00:00:00[Z]'), value)
    }

    if (selectedUserId && (role === SYSTEM_FIRST_ROLE || role === SYSTEM_FIFTH_ROLE)) {
      updateMemberSelection(selectedUserName, selectedUserId)
    } else if (role === SYSTEM_SECOND_ROLE || !selectedUserId) {
      updateMemberSelection(userName, userId)
    }

    if (role === SYSTEM_FIRST_ROLE) {
      memberInfoByOrg()
        .then((res) => {
          const newList = res.data.map((item: any) => ({
            label: item.fullName,
            value: item.userId,
          }))
          setMemberOption(newList)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [])

  const getTimeData = (date?: any, userId?: any) => {
    earlyStageCall()
    cancelTokenSource = axios.CancelToken.source()
    axios
      .get(`${API}/TimeClaim/GetUserTimeToClaim`, {
        params: {
          UserId: userId || localStorage.getItem('userId'),
          OrganizationId: localStorage.getItem('org_Id'),
          FromDate: date,
        },
        cancelToken: cancelTokenSource?.token,
      })
      .then((res) => {
        setTimeData(res.data.details)
        setUserDetail(res.data)
        setScreenLoader(false)
      })
  }

  const ClaimTime = (from: any, to: any, reason: any, user: any) => {
    let body = {}
    let URL
    if (claimStatus === 'Stopped' || claimStatus === 'Offline') {
      if (role === process.env.REACT_APP_SYSTEM_FIRST_ROLE) {
        body = {
          organizationId: localStorage.getItem('org_Id'),
          userId: user,
          claimStatus: '',
          adminUserId: localStorage.getItem('userId'),
          fromTime: from,
          toTime: to,
          requestDate: dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          reason: reason,
          activityStatusType: claimStatus,
        }
      } else {
        body = {
          organizationId: localStorage.getItem('org_Id'),
          userId: user,
          claimStatus: '',
          fromTime: from,
          toTime: to,
          requestDate: dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          reason: reason,
          activityStatusType: claimStatus,
        }
      }

      URL =
        role === process.env.REACT_APP_SYSTEM_FIRST_ROLE
          ? '/TimeClaim/AddUserTimeToClaimByAdminForStoppedorOffline'
          : '/TimeClaim/AddUserTimeToClaimForStoppedorOffline'

      cancelTokenSource = axios.CancelToken.source()
    } else {
      if (role === process.env.REACT_APP_SYSTEM_FIRST_ROLE) {
        body = {
          organizationId: localStorage.getItem('org_Id'),
          userId: user,
          adminUserId: localStorage.getItem('userId'),
          fromTime: from,
          toTime: to,
          requestDate: dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          reason: reason,
        }
      } else {
        body = {
          organizationId: localStorage.getItem('org_Id'),
          userId: user,
          fromTime: from,
          toTime: to,
          requestDate: dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          reason: reason,
        }
      }

      cancelTokenSource = axios.CancelToken.source()

      URL =
        role === process.env.REACT_APP_SYSTEM_FIRST_ROLE
          ? '/TimeClaim/AddUserTimeToClaimByAdmin'
          : '/TimeClaim/AddUserTimeToClaim'
    }
    //console.log(body)
    axios
      .post(`${API}${URL}`, body, {
        cancelToken: cancelTokenSource?.token, // Cancel token
      })
      .then((res) => {
        AlertModal(
          res.data.message,
          '',
          res.data.success ? 'success' : 'warning',
          false,
          '#7066E0',
          'Ok'
        )

        getTimeData(dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'), optionSelectedMember.value)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const Edit = (data: any, index: any) => {
    const inputRef = useRef<any>(null)
    const [reason, setReason] = useState<any>()
    const [error, setError] = useState(false)
    const [startEntries, setStartEntries] = useState<any[]>([])
    const [allStartEntries, setAllStartEntries] = useState<any[]>([])
    const [endEntries, setEndEntries] = useState<any[]>([])
    const [allEndEntries, setAllEndEntries] = useState<any[]>([])
    const [selectedStart, setSelectedStart] = useState('All')
    const [selectedEnd, setSelectedEnd] = useState('All')
    const [valueStartDate, setValueStartDate] = useState<any>([])
    const [valueEndDate, setValueEndDate] = useState<any>([])
    const [loggedTime, setLoggedTime] = useState<any>()

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, [])

    useEffect(() => {
      if (reason?.length > 500) {
        setError(true)
      }
    }, [reason])

    const callTimeClaimFunc = (from: any, to: any, message: any) => {
      if (message === '' || message?.length > 500 || message === undefined) {
        setError(true)
      } else {
        if (
          role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
          role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE
        ) {
          ClaimTime(from, to, message, optionSelectedMember.value)
        } else {
          ClaimTime(from, to, message, localStorage.getItem('userId'))
        }
        setUpdateState(null)
      }
    }

    useEffect(() => {
      // Assuming data is available
      if (data && data.name && data.name.startTime && data.name.endTime) {
        generateTimeIntervals(data.name.startTime, data.name.endTime, 5)
      }

      setValueStartDate({
        label: dayjs(data.name.startTime).format('HH:mm'),
        value: dayjs(data.name.startTime),
      })

      setValueEndDate({
        label: dayjs(data.name.endTime).format('HH:mm'),
        value: dayjs(data.name.endTime),
      })
    }, [data])

    const generateTimeIntervals = (startTime: any, endTime: any, intervalMinutes: any) => {
      const startArray = []
      let endArray: any = []

      // Convert start and end time to Date objects
      const startDate = new Date(startTime)
      const endDate = new Date(endTime)

      // Check if the difference is less than 5 minutes
      const timeDifference = endDate.getTime() - startDate.getTime()

      if (timeDifference <= 5 * 60000) {
        // If the difference is less than 5 minutes, directly add start and end times to the arrays
        startArray.push({
          label: dayjs(startDate).format('HH:mm'),
          value: dayjs(startDate),
        })

        endArray.push({
          label: dayjs(endDate).format('HH:mm'),
          value: dayjs(endDate),
        })
      } else {
        // Round up start time to the nearest interval
        const roundedStartTime = new Date(
          Math.ceil(startDate.getTime() / (intervalMinutes * 60000)) * (intervalMinutes * 60000)
        )

        // Include original startTime if it falls within the first interval
        if (startDate < roundedStartTime) {
          startArray.push({
            label: dayjs(startDate).format('HH:mm'),
            value: dayjs(startDate),
          })
        }

        // Populate startArray
        while (roundedStartTime < endDate) {
          startArray.push({
            label: dayjs(roundedStartTime).format('HH:mm'),
            value: dayjs(roundedStartTime),
          })
          roundedStartTime.setMinutes(roundedStartTime.getMinutes() + intervalMinutes)
        }
      }

      let newEndData: any
      if (timeDifference > 5 * 60000) {
        newEndData = startArray.slice(1)
      }

      let updatedStartDate = startArray.slice(0, -1)

      setStartEntries(updatedStartDate)
      setAllStartEntries(startArray)

      endArray = removeDuplicates(endArray)
      setEndEntries(newEndData)
      setAllEndEntries(startArray)
    }

    function removeDuplicates(array: any) {
      return array.filter((value: any, index: any, self: any) => {
        return (
          self.findIndex((v: any) => v.label === value.label && v.value === value.value) === index
        )
      })
    }

    const updatedgenerateTimeIntervals = (
      startTime: any,
      endTime: any,
      intervalMinutes: any,
      startOrend: any
    ) => {
      if (startOrend === 'end') {
        const newArray = allStartEntries.filter((data) => new Date(data.value) < new Date(endTime))
        setStartEntries(newArray)
      } else {
        const newArray = allEndEntries.filter((data) => new Date(data.value) > new Date(startTime))
        setEndEntries(newArray)
      }
    }

    const handleStartChange = (value: any) => {
      const loggedTime = dayjs(valueEndDate.value.diff(value.value)).format('HH:mm')
      setLoggedTime(loggedTime)
      setValueStartDate(value)
      updatedgenerateTimeIntervals(value.value, selectedEnd, 5, 'start')
    }

    const handleEndChange = (value: any) => {
      const loggedTime = dayjs(value.value.diff(valueStartDate.value)).format('HH:mm')
      setLoggedTime(loggedTime)
      setValueEndDate(value)
      updatedgenerateTimeIntervals(selectedStart, value.value, 5, 'end')
    }

    return (
      <>
        <tr key={index}>
          <td></td>

          <td style={{padding: '0rem .5rem 0rem 0rem', width: '9.3rem'}}>
            <span className='text-dark  d-flex fs-6 flex-column'>
              <Select
                components={makeAnimated()}
                value={valueStartDate}
                options={startEntries}
                placeholder='00:00'
                isClearable={false}
                menuPlacement='auto'
                menuPosition='fixed'
                isSearchable={true}
                closeMenuOnScroll={true}
                onChange={(e) => handleStartChange(e)}
              />
            </span>
          </td>

          <td style={{padding: '0rem .5rem 0rem 0rem', width: '9.3rem'}}>
            <span className='text-dark  d-flex  fs-6'>
              <Select
                components={makeAnimated()}
                value={valueEndDate}
                options={endEntries}
                placeholder='00:00'
                isClearable={false}
                menuPlacement='auto'
                menuPosition='fixed'
                isSearchable={true}
                closeMenuOnScroll={true}
                onChange={(e) => handleEndChange(e)}
              />
            </span>
          </td>

          <td>
            <span className='d-flex fs-6  justify-content-center'>
              {convertSecIntoHoursMinSec(data.name.spentTime)}
            </span>
          </td>

          <td>
            <span className='d-flex fs-6 justify-content-center'>
              {data.name.userActivityStatus}
            </span>
          </td>

          <td className='d-flex justify-content-center'>
            <div className='card-toolbar'>
              <span className={getColorStatus(data.name.requestStatus)}>
                {data.name.requestStatus}
              </span>
            </div>
          </td>

          <td style={{padding: '0px'}}>
            <span>
              <textarea
                maxLength={503}
                ref={inputRef}
                className='form-control form-control-flush'
                value={reason}
                rows={1}
                data-kt-element='input'
                placeholder='Type a Reason'
                onChange={(e) => {
                  setReason(e.target.value)
                  setError(false)
                }}
              ></textarea>
              {error && (
                <span className='text-danger' style={{marginLeft: '1rem'}}>
                  Fill Reason within 500 characters
                </span>
              )}
            </span>
          </td>

          <td>
            <div className='d-flex justify-content-center flex'>
              {!timeError && (
                <Tippy placement='top' content='Claim'>
                  <button
                    onClick={() =>
                      callTimeClaimFunc(
                        dayjs(valueStartDate.value).format('YYYY-MM-DDTHH:mm:ss[Z]'),
                        dayjs(valueEndDate.value).format('YYYY-MM-DDTHH:mm:ss[Z]'),
                        reason
                      )
                    }
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm mx-3'
                  >
                    <KTSVG path='/media/icons/duotune/general/gen016.svg' />
                  </button>
                </Tippy>
              )}

              <Tippy placement='top' content='Cancel'>
                <button
                  onClick={() => setUpdateState(null)}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm mx-3'
                >
                  <KTSVG path='/media/icons/duotune/general/x-lg.svg' />
                </button>
              </Tippy>
            </div>
          </td>
        </tr>
      </>
    )
  }

  const RequestElevateForClaim = (value: any) => {
    if (value === 'Idle' || value === 'Offline' || value === 'Stopped') return true
  }

  //Pagination

  const [currentItems, setCurrentItems] = useState<any>([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)

  const [rowPerPage, setRowPerPage] = useState(10)
  let itemsPerPage: number = rowPerPage

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(timeData.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(timeData.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, timeData])

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % timeData.length
    setUpdateState(null)
    setItemOffset(newOffset)
  }

  return (
    <>
      <div className={`card card-xxl-stretch mb-5 mt-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Time Claim</span>
          </h3>

          <div className='d-flex align-items-center justify-content-end'>
            <div style={{alignItems: 'center', display: 'flex'}}>
              {role === process.env.REACT_APP_SYSTEM_FIRST_ROLE && (
                <div>
                  <div
                    style={{width: '200px'}}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Search Member'
                  >
                    <Select
                      className='selectDropdown'
                      components={makeAnimated()}
                      value={optionSelectedMember}
                      options={memberOption}
                      placeholder='select member'
                      onChange={(item: any) => {
                        if (
                          ConfigDetails.role === process.env.REACT_APP_SYSTEM_FIRST_ROLE &&
                          ConfigDetails.selectedUserId !== item.value
                        ) {
                          dispatch(
                            userInfoActions.updateSelectedUser({
                              selectedUserName: item.label,
                              selectedUserId: item.value,
                            })
                          )
                        }
                        setOptionSelectedMember(item)

                        getTimeData(dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'), item.value)
                      }}
                      isClearable={false}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                  </div>
                </div>
              )}

              {SingleDatePickerComponent(startDate, handleDateChange, 'form-control ss_date')}
            </div>
          </div>
        </div>

        <div className='card-body py-3 d-flex justify-content-around mt-5 px-5'>
          <div
            className='border rounded min-w-175px py-3 px-4 mb-3 text-white d-flex flex-column align-items-center'
            style={{background: '#a600ff'}}
          >
            <div className='fs-6 fw-bolder'>Date</div>
            <div className='fw-semibold text-white' style={{color: 'rgb(17,17,100)'}}>
              {dayjs(startDate).format('DD/MM/YYYY')}
            </div>
          </div>
          <div
            className='border rounded min-w-175px py-3 px-4 mb-3 text-white d-flex flex-column align-items-center'
            style={{background: 'rgb(64, 132, 255)'}}
          >
            <div className='fs-6 fw-bolder'>First Activity</div>
            <div className='fw-semibold text-white' style={{color: 'rgb(17,17,100)'}}>
              {convertSecIntoAMPM(userDetail.firstActivity)}
            </div>
          </div>
          <div
            className='border rounded min-w-175px py-3 px-4 mb-3 text-white d-flex flex-column align-items-center'
            style={{background: 'rgb(244, 51, 74)'}}
          >
            <div className='fs-6 fw-bolder'>Last Activity</div>
            <div className='fw-semibold text-white' style={{color: 'rgb(17,17,100)'}}>
              {convertSecIntoAMPM(userDetail.lastActivity)}
            </div>
          </div>
          <div
            className='border rounded min-w-175px py-3 px-4 mb-3 text-white d-flex flex-column align-items-center'
            style={{background: 'rgb(98, 55, 221)'}}
          >
            <div className='fs-6 fw-bolder'>Total Time</div>
            <div className='fw-semibold text-white' style={{color: 'rgb(17,17,100)'}}>
              {convertSecIntoHoursMinSec(userDetail.totalTime)}
            </div>
          </div>
          <div
            className='border rounded min-w-175px py-3 px-4 mb-3 text-white d-flex flex-column align-items-center'
            style={{background: 'rgb(52, 168, 83)'}}
          >
            <div className='fs-6 fw-bolder'>Productive Time</div>
            <div className='fw-semibold text-white' style={{color: 'rgb(17,17,100)'}}>
              {convertSecIntoHoursMinSec(userDetail.totalProductiveTime)}
            </div>
          </div>
          <div
            className='border rounded min-w-175px py-3 px-4 mb-3 text-white d-flex flex-column align-items-center'
            style={{background: 'rgb(251, 188, 4)'}}
          >
            <div className='fs-6 fw-bolder'>Idle Time</div>
            <div className='fw-semibold text-white' style={{color: 'rgb(17,17,100)'}}>
              {convertSecIntoHoursMinSec(userDetail.totalIdleTime)}
            </div>
          </div>
        </div>
      </div>

      <div className='card py-9 px-10'>
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4' style={{border: '1px solid '}}>
            {/* begin::Table head */}
            <thead>
              <tr
                className='fw-bolder'
                style={{background: '#728FCE', fontSize: '15px', borderBottom: '1px solid'}}
              >
                <th></th>
                <th className='min-w-100px'>Start Time</th>
                <th className='min-w-100px'>End Time</th>
                <th className='min-w-100px text-center'>Spend Time</th>
                <th className='min-w-100px text-center'>Activity Status</th>
                <th className='min-w-100px text-center'>Request Status</th>
                <th className='min-w-100px text-center'>Reason</th>
                <th className='min-w-150px text-center'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {currentItems.length > 0 &&
                currentItems.map((data: any, index: any) =>
                  updateState === index ? (
                    <Edit name={data} index={data.startTime} />
                  ) : (
                    <tr key={index} style={{fontSize: '12px'}}>
                      <td></td>
                      <td>
                        <span className='text-dark  d-flex fs-6'>
                          {dayjs(data.startTime).format('HH:mm')}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark  d-flex  fs-6'>
                          {dayjs(data.endTime).format('HH:mm')}
                        </span>
                      </td>

                      <td>
                        <span className='d-flex fs-6  justify-content-center'>
                          {convertSecIntoHoursMinSec(data.spentTime)}
                        </span>
                      </td>
                      <td>
                        <span className='d-flex fs-6 justify-content-center'>
                          {data.userActivityStatus}
                        </span>
                      </td>
                      <td>
                        <span className='d-flex fs-6 justify-content-center'>
                          <div className='card-toolbar'>
                            <span className={getColorStatus(data.requestStatus)}>
                              {data.requestStatus}
                            </span>
                          </div>
                        </span>
                      </td>
                      <td style={{width: '25rem'}}>
                        <span
                          className='d-flex fs-6 justify-content-center '
                          style={{lineBreak: 'anywhere'}}
                        >
                          {data.reason}
                        </span>
                      </td>
                      <td>
                        {RequestElevateForClaim(data.userActivityStatus) &&
                          (data.requestStatus == '' || data.requestStatus == 'Rejected') && (
                            <div className='d-flex justify-content-center'>
                              <Tippy placement='top' content='Claim Time'>
                                <div
                                  onClick={() => {
                                    setUpdateState(index)
                                    setClaimStatus(data.userActivityStatus)
                                    //ClaimTime(dayjs(data.startTime).format(), dayjs(data.endTime).format())
                                  }}
                                  data-bs-toggle='tooltip'
                                  data-bs-placement='top'
                                  data-bs-trigger='hover'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 '
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/claim-time.svg'
                                    className='svg-icon-3'
                                  />
                                </div>
                              </Tippy>
                            </div>
                          )}
                      </td>
                    </tr>
                  )
                )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}

          {screenloader
            ? Loader('100px')
            : timeData.length === 0 && <h2 className='noRecordFound'>No Records Found</h2>}
        </div>

        {timeData.length > 10 && (
          <div className='d-flex align-items-center justify-content-center position-relative'>
            <div style={{left: '0', position: 'absolute', top: '0'}}>
              {RowsPerPage(
                setForcePage,
                setRowPerPage,
                setItemOffset,
                rowPerPage,
                timeData.length,
                '1rem'
              )}
            </div>

            {timeData.length > rowPerPage && Pagination(handlePageClick, pageCount, 0)}
          </div>
        )}
      </div>
    </>
  )
}

export default TimeClaim
